import { registerApplication, start } from 'single-spa';
import {
  constructRoutes,
  constructLayoutEngine,
} from 'single-spa-layout';
import microfrontendLayout from './microfrontend-layout.html';
import {
  getSubjectLanguages,
  getSubjectDefaultLanguage,
  getAccountInfo,
  getCurrency,
  getUserDetails,
  getTenantDetails,
  getPermissions,
  loadTranslationsForLocale,
  // userDetailsState,
} from '@sales-i/utils';
// import chameleon from '@chamaeleonidae/chmln';

async function initializeApp() {
  await loadTranslationsForLocale();
  await getUserDetails();
  await getSubjectLanguages();
  const defaultLanguage = getSubjectDefaultLanguage();
  // send default language to mobile
  try {
    window.webkit.messageHandlers.defaultLanguageMessageHandler.postMessage(defaultLanguage);
  } catch (err) {
    // onErr
  }
  // const userData = userDetailsState.getValue().data || {};
  getCurrency();
  getTenantDetails();
  getPermissions();
  getAccountInfo();

  // Define the routes based on the layout file
  const routes = constructRoutes(microfrontendLayout);

  // Custom function to check if the path is /login, /logout, or /masquerading
  function isLoginLogoutOrMasquerading(location) {
    return ['/login', '/logout', '/masquerading'].includes(location.pathname);
  }

  // Hardcoded applications based on authentication status
  const applications = [
    {
      name: '@sales-i/auth',
      app: () => System.import('@sales-i/auth'),
      activeWhen: () => true,
    },
    {
      name: '@sales-i/header',
      app: () => System.import('@sales-i/header'),
      activeWhen: () => true,
    },
    {
      name: '@sales-i/breadcrumbs',
      app: () => System.import('@sales-i/breadcrumbs'),
      activeWhen: () => true,
    },
    {
      name: '@sales-i/aiquery',
      app: () => System.import('@sales-i/aiquery'),
      activeWhen: location => !isLoginLogoutOrMasquerading(location),
    },
    {
      name: '@sales-i/admin',
      app: () => System.import('@sales-i/admin'),
      activeWhen: location => location.pathname.startsWith('/admin') && !isLoginLogoutOrMasquerading(location),
    },
    {
      name: '@sales-i/crm',
      app: () => System.import('@sales-i/crm'),
      activeWhen: location => !location.pathname.startsWith('/admin') && !isLoginLogoutOrMasquerading(location),
    },
  ];

  //initialise chameleon
  // const chmlnId = process.env.VUE_APP_CHMLN_ID;
  // chameleon.init(
  //   chmlnId,
  //   { 
  //     fastUrl: 'https://fast.chameleon.io/',
  //     forceOverride: false, 
  //   }
  // );

  // chameleon.identify(userData.id, {
  //   email: userData.email,
  //   name: `${userData.first_name} ${userData.last_name}`,
  // });

  const layoutEngine = constructLayoutEngine({ routes, applications });
  // Register applications and start the layout engine
  applications.forEach(app => registerApplication(app));
  layoutEngine.activate();

  // Start single-spa
  start();
}

// Initialize the app
initializeApp();